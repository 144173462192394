<script lang="ts">
import { gsap } from 'gsap'
import { defineComponent, onMounted, ref, watch } from 'vue'

import Logo from '@/assets/svg/logo-extended.svg'

export default defineComponent({
  name: 'ThePreloader',
  components: { Logo },
  setup (props, { expose }) {
    const visible = ref(true)
    const progress = ref(0)
    let tl

    function onVisibleChange () {
      if (!tl) return

      tl.tweenTo(visible.value ? 'show' : 'hide', {
        duration: visible.value ? 0.5 : 1,
        ease: visible.value ? 'circ.out' : 'circ.in',
        onStart: () => {
          gsap.set('#app-loading', { autoAlpha: 1 })
        },
        onComplete: () => {
          gsap.set('#app-loading', { autoAlpha: visible.value ? 1 : 0 })
        }
      })
    }

    watch(visible, onVisibleChange, { immediate: true })

    onMounted(() => {
      tl = gsap.timeline({ paused: true })
      tl.addLabel('show')
      tl.fromTo('#app-loading .app-loading-element', { autoAlpha: 1 }, { autoAlpha: 0, duration: 0.3 })
      tl.fromTo('#app-loading .mask-path', { scale: 0 }, { scale: 1.3, duration: 1 })
      tl.addLabel('hide')
      onVisibleChange()
    })

    expose({ visible, progress })

    return {
      visible,
      progress
    }
  }
})
</script>

<template>
  <div id="app-loading">
    <svg class="app-loading-bg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 319 434" width="100%" height="100%" preserveAspectRatio="xMidYMid slice">
      <mask id="mask">
        <rect x="0" y="0" width="319" height="434" fill="white" />
        <path
          class="mask-path"
          clip-rule="evenodd"
          fill="black"
          transform-origin="50% 50%"
          d="M2 159.215v115.381c0 86.828 70.388 157.216 157.215 157.216 86.828 0 157.216-70.388 157.216-157.216V159.215C316.431 72.388 246.043 2 159.215 2 72.388 2 2 72.388 2 159.215ZM0 274.596c0 87.932 71.283 159.216 159.215 159.216 87.933 0 159.216-71.284 159.216-159.216V159.215C318.431 71.283 247.148 0 159.215 0 71.283 0 0 71.283 0 159.215v115.381Z"
        />
      </mask>
      <rect x="0" y="0" width="319" height="434" mask="url(#mask)" fill="#0c121a" />
    </svg>

    <Logo class="app-loading-element app-loading-logo" />
    <svg class="app-loading-element app-loading-line" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 319 434">
      <rect opacity="0.2" x="1" y="432.812" width="431.812" height="316.431" rx="158.215" transform="rotate(-90 1 432.812)" stroke="white" stroke-width="2" />
      <rect class="app-loading-path" x="1" y="432.812" width="431.812" height="316.431" rx="158.215" transform="rotate(-90 1 432.812)" stroke="currentColor" stroke-width="2" />
    </svg>
    <Transition>
      <span v-if="progress !== 0" class="app-loading-element app-loading-progress">{{ visible ? progress : 100 }}%</span>
    </Transition>
    <p class="app-loading-element app-loading-text">An unexpected virtual world where<br />Fashion, Art and Design express their universal power</p>
  </div>
</template>

<style lang="scss">
#app-loading {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: #fff;
}

.app-loading-bg {
  position: absolute;
  top: 0;
  left: 0;
}

.app-loading-logo {
  position: absolute;
  z-index: 2;
  top: 15%;
  left: 50%;
  width: rem(200px);
  height: rem(38px);
  transform: translate(-48%, -50%);
}

.app-loading-line {
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  width: 40%;
  height: 40%;
  color: red;
  transform: translate(-50%, -50%);
}

.app-loading-text {
  position: fixed;
  bottom: 15%;
  width: 90%;
  margin: 0 5%;
  color: white;
  font-family: Helvetica, sans-serif;
  font-size: rem(16px);
  line-height: 1.3;
  text-align: center;
  transform: translate(0%, 50%);
}

.app-loading-path {
  animation: dash 1.5s ease-in-out infinite;
  stroke-dasharray: 612 612;
}

.app-loading-progress {
  position: absolute;
  z-index: 3;
  top: 50%;
  left: 50%;
  display: block;
  color: red;
  font-family: Spaco, sans-serif;
  font-size: rem(42px);
  transform: translate(-50%, -50%);

  @screen md {
    font-size: rem(68px);
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: 612;
  }

  100% {
    stroke-dashoffset: -612;
  }
}
</style>
